import cn from 'classnames';
import type {ReactNode} from 'react';

import type {CtaComponent} from '@/types';
import type {ColorSchemeEnum} from '@/enums';
import {AlignmentEnum, CtaVariant} from '@/enums';
import {SmallHtml} from '@/components/shared/HtmlElements';
import ModalVideoButton from '@/components/brochureV2/primitive/SpecialtyButtons/ModalVideoButton/ModalVideoButton';
import EmailForm from '@/components/brochureV2/primitive/EmailForm/EmailForm';
import WaitlistFormComponent from '@/components/brochureV2/primitive/WaitlistForm/WaitlistForm';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonTheme,
  ButtonVariant,
  getButtonTheme,
} from '@/components/brochureV2/primitive/Button/types';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import SignupPaidTrialPromo from '@/components/shared/SignupPaidTrialPromo/SignupPaidTrialPromo';
import {useSignupPaidTrialMessage} from '@/hooks/experiments/useSignupPaidTrialMessage';
import useSignup from '@/hooks/useSignup';
import {useTranslations} from '@/hooks/useTranslations';

function Disclaimer({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <SmallHtml className={cn('block text-body-sm', className)}>
      {children}
    </SmallHtml>
  );
}

interface CTAProps {
  cta: CtaComponent;
  isFirstSection?: boolean;
  buttonClass?: string;
  formClass?: string;
  inputWrapperClass?: string;
  alignment?: AlignmentEnum;
  colorScheme?: ColorSchemeEnum;
  displayPromoBanner?: boolean;
}

// TODO: Refactor this to simplify it
// eslint-disable-next-line complexity
export default function CTA({
  cta,
  isFirstSection,
  colorScheme,
  formClass,
  inputWrapperClass,
  buttonClass,
  alignment = AlignmentEnum.Center,
  displayPromoBanner = false,
}: CTAProps) {
  const {t} = useTranslations();
  const {signupUrl, signupName} = useSignup();
  const isExpSignupPaidTrialMessage = useSignupPaidTrialMessage();

  const variant = cta.ctaVariant;
  const buttonTheme = colorScheme
    ? getButtonTheme(colorScheme)
    : ButtonTheme.Dark;

  const defaultButtonText = t('global:signup.buttonText');
  const defaultDisclaimerHtml = t('global:signup.disclaimerHtml');
  const defaultLabel = t('global:signup.label');
  const defaultPlaceholder = t('global:signup.placeholder');

  switch (variant) {
    case CtaVariant.Disabled:
      return null;
    case CtaVariant.EmailSignup:
      return (
        <>
          <EmailForm
            className={formClass}
            inputWrapperClass={inputWrapperClass}
            button={{
              href: cta?.buttonPrimary?.url ?? signupUrl,
              text: cta?.buttonPrimary?.text ?? defaultButtonText,
              name: cta?.buttonPrimary?.name ?? signupName,
            }}
            emailFieldPlaceholder={
              cta?.ctaEmail?.placeholder ?? defaultPlaceholder
            }
            inHero={isFirstSection}
            alignment={alignment}
          />
          {cta &&
            (isExpSignupPaidTrialMessage && displayPromoBanner ? (
              <SignupPaidTrialPromo
                mode={
                  [ButtonTheme.Dark, ButtonTheme.PosDark].includes(buttonTheme)
                    ? 'light'
                    : 'dark'
                }
                className="mt-7 px-2"
                alignment={alignment}
                is1F
              />
            ) : (
              <>
                <Spacer size="sm" />
                <Disclaimer>
                  {cta?.ctaDisclaimer?.contentHtml ?? defaultDisclaimerHtml}
                </Disclaimer>
              </>
            ))}
        </>
      );
    case CtaVariant.WaitlistForm:
      if (!cta?.ctaWaitlist?.subscriptionId) return null;
      return (
        <>
          <WaitlistFormComponent
            {...cta?.ctaWaitlist}
            buttonText={cta.buttonPrimary?.text}
            className={formClass}
            inputWrapperClass={inputWrapperClass}
            alignment={alignment}
          />
          {cta?.ctaDisclaimer && (
            <>
              <Spacer size="sm" />
              <Disclaimer>
                {cta?.ctaDisclaimer?.contentHtml ?? defaultDisclaimerHtml}
              </Disclaimer>
            </>
          )}
        </>
      );
    case CtaVariant.Custom:
    case CtaVariant.Signup:
    default:
      return (
        <>
          <div className="basis-full">
            <div
              className={cn('flex flex-wrap gap-x-4', {
                'justify-center': alignment === AlignmentEnum.Center,
              })}
            >
              {cta?.buttonPrimary?.video ? (
                <ModalVideoButton
                  buttonLabel={cta.buttonPrimary.name ?? defaultLabel}
                  id={cta.buttonPrimary.name ?? signupName}
                  text={cta.buttonPrimary.text ?? defaultButtonText}
                  videoId={cta.buttonPrimary.video.videoId ?? ''}
                />
              ) : (
                <>
                  <div className="md:inline-block">
                    <Button
                      className={buttonClass}
                      theme={buttonTheme}
                      type="link"
                      href={
                        cta?.buttonPrimary?.url
                          ? cta.buttonPrimary?.url
                          : signupUrl
                      }
                      componentName={
                        (cta?.buttonPrimary?.url
                          ? cta.buttonPrimary?.name
                          : signupName) || ''
                      }
                    >
                      {cta?.buttonPrimary?.text ?? defaultButtonText}
                    </Button>
                  </div>
                  {cta?.buttonSecondary?.url && (
                    <div className="md:inline-block">
                      <Button
                        theme={buttonTheme}
                        variant={ButtonVariant.Secondary}
                        type="link"
                        href={cta?.buttonSecondary?.url ?? signupUrl}
                        componentName={cta?.buttonSecondary?.name ?? ''}
                      >
                        {cta?.buttonSecondary?.text ?? defaultButtonText}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {isExpSignupPaidTrialMessage && displayPromoBanner ? (
            <SignupPaidTrialPromo
              mode={
                [ButtonTheme.Dark, ButtonTheme.PosDark].includes(buttonTheme)
                  ? 'light'
                  : 'dark'
              }
              className="mt-7 px-2"
              alignment={alignment}
              signupFormNoEmail={
                !cta?.buttonPrimary?.url ||
                cta?.buttonPrimary?.url === signupUrl
                  ? false
                  : true
              }
            />
          ) : (
            cta?.ctaDisclaimer && (
              <>
                <Spacer size="sm" />
                <Disclaimer>
                  {cta?.ctaDisclaimer?.contentHtml ?? defaultDisclaimerHtml}
                </Disclaimer>
              </>
            )
          )}
        </>
      );
  }
}
