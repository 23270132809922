import type {
  WaitlistFormResponse,
  WaitlistFormErrorResponse,
  WaitlistFormSuccessResponse,
} from './handleWaitlistFormSubmission.server';

export function isErrorResponseForCurrentForm(
  response: any | undefined,
  subscriptionId: WaitlistFormResponse['subscriptionId'],
): response is WaitlistFormErrorResponse {
  return (
    response?.status === 'error' && response?.subscriptionId === subscriptionId
  );
}

export function isSuccessResponseForCurrentForm(
  response: any,
  subscriptionId: WaitlistFormResponse['subscriptionId'],
): response is WaitlistFormSuccessResponse {
  return (
    response?.status === 'success' &&
    response?.subscriptionId === subscriptionId
  );
}
