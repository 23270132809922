import cn from 'classnames';
import {Form, useActionData, useNavigation} from '@remix-run/react';
import type {ReactNode} from 'react';

import {SHOULD_LOG} from '@/constants';
import type {CtaWaitlistComponent} from '@/types';
import {AlignmentEnum} from '@/enums';
import type handleWaitlistFormSubmission from '@/utils/server/action/waitlist/handleWaitlistFormSubmission.server';
import {
  isErrorResponseForCurrentForm,
  isSuccessResponseForCurrentForm,
} from '@/utils/server/action/waitlist/utils';
import {useTranslations} from '@/components/shared/Page/I18N';
import Checkmark from '@/components/brochureV2/icons/Checkmark';
import InfoMinor from '@/components/brochureV2/icons/InfoMinor';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Typography from '@/components/base/elements/Typography/Typography';
import {WaitlistFormHoneyPotField} from '@/utils/server/action/waitlist/constants';

import Button from '../Button/Button';

export interface WaitlistFormProps extends CtaWaitlistComponent {
  buttonText: string | undefined;
  className?: string;
  inputWrapperClass?: string;
  bgColorClass?: string;
  subscriptionId: string;
  alignment?: AlignmentEnum;
  formScope?: string;
}

type StatusTextProps = {
  children: ReactNode;
};

function getErrorString(value: any, errorMap: {[key: string]: string}) {
  if (!errorMap[value ?? '']) {
    if (SHOULD_LOG) {
      // eslint-disable-next-line no-console
      console.log('Error should be of type ' + Object.keys(errorMap).join('|'));
    }
    return;
  }

  return errorMap[value];
}

function ErrorText({children}: StatusTextProps) {
  return (
    <span className="py-2 pl-6 text-xs">
      <InfoMinor className="text-state-error inline mr-2 align-bottom" />
      {children}
    </span>
  );
}

// Hidden field that, on submission will prevent the submission from being sent.
// The mechanism for hiding it is delicate, It needs to be fillable by bots, without being autocompleted
// See: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill for details
function HoneyPotField() {
  return (
    <input
      className="absolute left-[-9999px]"
      id="field1"
      name={WaitlistFormHoneyPotField}
    />
  );
}

const FormButton = ({
  children,
  disabled = false,
  fullWidth = false,
  success = false,
}: {
  children: string;
  disabled?: boolean;
  fullWidth?: boolean;
  success?: boolean;
}) => {
  const {t} = useTranslations();
  const classNames = cn('whitespace-nowrap', {
    'disabled:text-black': success,
  });
  return (
    <Button
      style={
        fullWidth
          ? {width: '100%', marginTop: '1rem'}
          : {paddingTop: 16, paddingBottom: 16}
      }
      type="submit"
      componentName="join-waitlist-form-button"
      className={classNames}
      disabled={disabled || success}
    >
      {success ? (
        <>
          <Checkmark className="inline mr-2 align-baseline" width="14px" />
          {t('global:waitlistForm.successMessage')}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default function WaitlistForm({
  buttonText,
  placeholder,
  label,
  className,
  inputWrapperClass,
  bgColorClass,
  alignment = AlignmentEnum.Left,
  subscriptionId,
  listId,
  formScope,
  successMessage,
}: WaitlistFormProps) {
  const {t} = useTranslations();
  const submitResponse = useActionData<typeof handleWaitlistFormSubmission>();
  const navigation = useNavigation();
  const errorMap: {[key: string]: string} = {
    general: t('global:waitlistForm.errors.general'),
    emailFormat: t('global:waitlistForm.errors.emailFormat'),
  };
  const defaultPlaceholder = t('global:waitlistForm.placeholder');
  const defaultButtonText = t('global:waitlistForm.buttonText');
  // Accounts for multiple forms on the page so submission on one list does not impact the state of the other
  // This assumes that all forms on the page with the same subId should show the success/error state of any instance
  const submissionSuccess = isSuccessResponseForCurrentForm(
    submitResponse,
    subscriptionId,
  );
  const submissionError = isErrorResponseForCurrentForm(
    submitResponse,
    subscriptionId,
  );

  const formSuccessMessage = (
    <>
      <Spacer size="sm" />
      <div
        aria-live="polite"
        className="sm:w-[498px] p-6 bg-white rounded-2xl shadow-light space-y-2 text-left"
      >
        {successMessage?.heading && (
          <Typography as="p" size="t7">
            {successMessage?.heading}
          </Typography>
        )}
        {successMessage?.contentHtml && (
          <Typography as="p" size="p">
            {successMessage?.contentHtml}
          </Typography>
        )}
      </div>
    </>
  );

  return (
    <div>
      {label && (
        <Typography as="p" className="sr-only">
          {label}
        </Typography>
      )}
      <Form
        method="post"
        noValidate
        replace
        className={cn(className, 'flex w-full flex-col justify-center', {
          'md:w-auto': alignment,
          'items-center': alignment === AlignmentEnum.Center,
        })}
      >
        <div
          data-component-name="waitlist-form-input-wrapper"
          className={cn(
            'box-border flex h-16 w-full items-center justify-between rounded-full border pl-6 pr-1 sm:w-[498px]',
            // this is an order-of-declaration issue in the generated stylesheet
            inputWrapperClass || 'default:border-shade-30',
            bgColorClass || 'bg-white',
            {
              'border-state-error': submissionError,
            },
          )}
        >
          <input
            className="text-shade-70 h-14 w-full bg-transparent sm:w-72 outline-none"
            type="email"
            spellCheck="false"
            autoComplete="email"
            name="email"
            placeholder={placeholder ?? defaultPlaceholder}
            disabled={navigation.state === 'submitting' || submissionSuccess}
          />
          <input type="hidden" name="subscriptionId" value={subscriptionId} />
          {listId && (
            <input type="hidden" name="dataExtensionKey" value={listId} />
          )}
          {formScope && (
            <input type="hidden" name="formScope" value={formScope} />
          )}
          <div className="hidden sm:block">
            <FormButton
              success={submissionSuccess}
              disabled={navigation.state === 'submitting'}
            >
              {buttonText ?? defaultButtonText}
            </FormButton>
          </div>
        </div>
        <div className="block w-full sm:hidden">
          <FormButton
            success={submissionSuccess}
            disabled={navigation.state === 'submitting'}
            fullWidth
          >
            {buttonText ?? defaultButtonText}
          </FormButton>
        </div>

        <HoneyPotField />

        {submissionError && (
          <ErrorText>
            {getErrorString(submitResponse.errorCode, errorMap)}
          </ErrorText>
        )}

        {submissionSuccess && successMessage && formSuccessMessage}
      </Form>
    </div>
  );
}
