import {useCallback, useState} from 'react';

import Modal from '@/components/shared/Modal/Modal';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import {ModalSizeEnum} from '@/enums';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonTheme,
  ButtonVariant,
} from '@/components/brochureV2/primitive/Button/types';
import {useTranslations} from '@/hooks/useTranslations';

interface ModalVideoButtonProps {
  buttonLabel?: string;
  closeLabel?: string;
  id: string;
  text: string;
  videoId: string;
  theme?: ButtonTheme;
  aspectRatio?: string;
  variant?: ButtonVariant;
  modalSize?: ModalSizeEnum;
}

/**
 * @deprecated
 */
export default function ModalVideoButton({
  buttonLabel = 'watch video',
  closeLabel,
  id,
  text,
  videoId,
  aspectRatio = '16/9',
  theme = ButtonTheme.Light,
  variant = ButtonVariant.Primary,
  modalSize = ModalSizeEnum.Large,
}: ModalVideoButtonProps) {
  const {t} = useTranslations();
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <>
      <Button
        aria-label={buttonLabel}
        aria-haspopup="dialog"
        tabIndex={0}
        onClick={() => {
          setModalOpen(true);
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            setModalOpen(true);
          }
        }}
        theme={theme}
        variant={variant}
        componentName="ModalVideoButton"
        disabled={false}
        className="flex items-center gap-2"
      >
        {text}
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
          <path
            d="M16.5 10.0003C16.5 10.3523 16.316 10.6773 16.015 10.8573L6.015 16.8573C5.856 16.9523 5.678 17.0003 5.5 17.0003C5.33 17.0003 5.16 16.9573 5.007 16.8703C4.694 16.6923 4.5 16.3603 4.5 16.0003V4.00035C4.5 3.64035 4.694 3.30835 5.007 3.13035C5.32 2.95135 5.706 2.95735 6.015 3.14335L16.015 9.14335C16.316 9.32335 16.5 9.64835 16.5 10.0003Z"
            fill="currentColor"
          />
        </svg>
      </Button>
      <Modal
        closeLabel={closeLabel}
        handleClose={handleClose}
        id={id}
        isActive={modalOpen}
        modalSize={modalSize}
        aria-label={t('global:ariaLabels.videoModal')}
      >
        <WistiaVideoPlayer
          autoPlay
          videoId={videoId}
          aspectRatio={aspectRatio}
        />
      </Modal>
    </>
  );
}
